@charset "utf-8";

// FontAwesome
$fa_font_path: "~@fortawesome/fontawesome-free/webfonts";
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';

// OpenSans
$FontPathOpenSans: "~open-sans-fonts/open-sans";
@import '~open-sans-fonts/open-sans';
$family-primary: 'Open Sans';

//Variables
$mobile: 500px;

// Bulma

@import "~bulmaswatch/flatly/variables"; //bulmaswatch theme variables
@import "~bulma/bulma";
@import "~bulmaswatch/flatly/overrides"; //bulmaswatch theme overrides

@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/derived-variables";

.navbar-item img {
    max-height: unset;
}

.banner-logo img {
    height: 5rem;
}

.banner-profile img {
    height: 3rem;
}

.navbar {
    border-radius: unset;
}

ul {
    padding-left: 2em;
}

body {
    margin-bottom: $navbar-height;
}

.field-label {
    text-align: left;
    font-weight: bold;
}

.modal-card-title {
    flex-shrink: 1;
}

.modal {
    overflow: auto;
    background-color: rgba(10, 10, 10, 0.86);
}

.modal-card {
    overflow: unset;
    @media screen and (max-width: $tablet) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

.modal-card-body {
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: unset;
}

.modal-background {
    background-color: rgba(0, 0, 0, 0);
}

.modal-card-foot {
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: -10px 0 0 0;
}

.container.is-fluid {
    padding: 16px;
    background-color: $body-background-color;
}

#footer-touch {
    height: calc($navbar-height / 2);
    min-height: unset;
    z-index: auto;
}

#footer-desktop {
    @media screen and (max-height: 500px) {
        position: unset;
    }
    z-index: auto;
}

#footer-level {
    flex-grow: 1;
    background-color: $footer-background-color;
    color: $footer-color;
    padding-left: 1rem;
    padding-right: 1rem;
}

@media screen and (max-width: $mobile) {
    #nav-title {
        font-size: 1.5rem;
    }
}

.radio {
    padding-right: 0.5em;
    input {
        margin-right: 0.5em;
    }
}

// Tooltip container
.tooltip {
    position: relative;
    display: inline-block;
}

// Tooltip text
.tooltip .tooltip-text {
    visibility: hidden;
    width: 14rem;
    background: rgba(74,74,74,0.9);
    color: #fff;
    text-align: center;
    padding: 0.3rem 0.7rem;
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    bottom: 110%;
    left: 50%;
    margin-left: -7rem; // Use half of the width to center the tooltip
}

// Show the tooltip text when you mouse over the tooltip container
.tooltip:hover .tooltip-text {
    visibility: visible;
}

// Tooltip arrow
.tooltip .tooltip-text::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(74,74,74,0.9) transparent transparent transparent;
}

//Field hiding
.field[data-sub_du-hidden] {
    visibility: collapse;
    display: none;
}

.display-field[data-sub_du-hidden] {
    visibility: collapse;
    display: none;
}

.field[data-sub_du-disabled] {
    visibility: collapse;
    display: none;
}

// Overlays a spinner on the element.
// Most commonly used with a containing div.
.spinner {
    &.is-loading {
        position: relative;
        pointer-events: none;
        opacity: 0.5;

        &:after {
            @include loader;
            position: absolute;
            top: calc(50% - 2.5em);
            left: calc(50% - 2.5em);
            width: 5em;
            height: 5em;
            border-width: 0.25em;
        }
    }
}

//React Easy Edit Style Overrides
easy-edit-input-textarea {
  @extend input;
  box-shadow: $input-shadow;
  max-width: 100%;
  width: 100%;
  &[readonly] {
    box-shadow: none;
  }  // Colors
  @each $name, $pair in $textarea-colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      border-color: $color;
      &:focus,
      &.is-focused,
      &:active,
      &.is-active {
        box-shadow: $input-focus-box-shadow-size bulmaRgba($color, 0.25);
      }  // Sizes
    }
  }
  &.is-small {
    @include control-small;
  }
  &.is-medium {
    @include control-medium;
  }
  &.is-large {
    @include control-large;
  }  // Modifiers
  &.is-fullwidth {
    display: block;
    width: 100%;
  }
  &.is-inline {
    display: inline;
    width: auto;
  }
}
.easy-edit-input {
    @extend easy-edit-input-textarea;

    &.is-rounded {
        border-radius: $radius-rounded;
        padding-left: calc(#{$control-padding-horizontal} + 0.375em);
        padding-right: calc(#{$control-padding-horizontal} + 0.375em);
    }

    &.is-static {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
    }
}
.easy-edit-textarea {
    @extend easy-edit-input-textarea;
    display: block;
    max-width: 100%;
    min-width: 100%;
    padding: $textarea-padding;
    resize: vertical;

    &:not([rows]) {
        max-height: $textarea-max-height;
        min-height: $textarea-min-height;
    }

    &[rows] {
        height: initial;
    }
    // Modifiers
    &.has-fixed-size {
        resize: none;
    }
}

.easy-edit-display:hover {
    font-style: italic;
}

// [BEGIN] File upload component
.file-name {
    max-width: 30em;
}

.file-layout {
    flex-direction: column;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    max-width: 30em;
}
// [END] File upload component